import "./App.css";
import { Printer, Server } from "react-feather";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Hier entsteht etwas wundervolles...</p>
        <Server className="App-logo" size={50} />
        <p>
          Auch Webseiten müssen erst gedruckt werden <Printer />
        </p>
      </header>
    </div>
  );
}

export default App;
